* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
address,
blockquote,
div,
dl,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul,
dd,
dt,
li,
tbody,
td,
tfoot,
th,
thead,
tr,
button,
del,
ins,
map,
object,
a,
abbr,
acronym,
b,
bdo,
big,
br,
cite,
code,
dfn,
em,
i,
img,
kbd,
q,
samp,
small,
span,
strong,
sub,
sup,
tt,
var,
legend,
fieldset,
p,
aside,
header,
footer,
section,
article {
    font-family: 'Metropolis';
    border: medium none;
    margin: 0;
    padding: 0;
}

/* :focus {
    outline: none !important;
    border: none !important;
    box-shadow: unset !important;
} */

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraBold.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraBold.woff') format('woff'),
        url('../fonts/Metropolis-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Bold.woff2') format('woff2'),
        url('../fonts/Metropolis-Bold.woff') format('woff'),
        url('../fonts/Metropolis-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Black.woff2') format('woff2'),
        url('../fonts/Metropolis-Black.woff') format('woff'),
        url('../fonts/Metropolis-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-BlackItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-BlackItalic.woff') format('woff'),
        url('../fonts/Metropolis-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-LightItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-LightItalic.woff') format('woff'),
        url('../fonts/Metropolis-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Semi';
    src: url('../fonts/Metropolis-SemiBold.woff2') format('woff2'),
        url('../fonts/Metropolis-SemiBold.woff') format('woff'),
        url('../fonts/Metropolis-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-ThinItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-ThinItalic.woff') format('woff'),
        url('../fonts/Metropolis-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Regular.woff2') format('woff2'),
        url('../fonts/Metropolis-Regular.woff') format('woff'),
        url('../fonts/Metropolis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Medium.woff2') format('woff2'),
        url('../fonts/Metropolis-Medium.woff') format('woff'),
        url('../fonts/Metropolis-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/Metropolis-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraLight.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraLight.woff') format('woff'),
        url('../fonts/Metropolis-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Thin.woff2') format('woff2'),
        url('../fonts/Metropolis-Thin.woff') format('woff'),
        url('../fonts/Metropolis-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-BoldItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-BoldItalic.woff') format('woff'),
        url('../fonts/Metropolis-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Semi';
    src: url('../fonts/Metropolis-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-SemiBoldItalic.woff') format('woff'),
        url('../fonts/Metropolis-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-RegularItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-RegularItalic.woff') format('woff'),
        url('../fonts/Metropolis-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-MediumItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-MediumItalic.woff') format('woff'),
        url('../fonts/Metropolis-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis Extra';
    src: url('../fonts/Metropolis-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/Metropolis-ExtraLightItalic.woff') format('woff'),
        url('../fonts/Metropolis-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Light.woff2') format('woff2'),
        url('../fonts/Metropolis-Light.woff') format('woff'),
        url('../fonts/Metropolis-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}



h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Metropolis';
}

h1 {
    font-family: 'Metropolis';
    font-family: 'Metropolis';
}

h2 {
    font-family: 'Metropolis';
}

h3 {
    font-family: 'Metropolis';
}

p {}

h4 {
    font-family: 'Metropolis';
    font-size: 18px;
}

img,
fieldset {
    border: 0 none;
}

a {
    font-family: 'Metropolis';
    border: none;
    outline: none;
}

input[type="submit"],
select {
    font-family: 'Metropolis';
    -webkit-appearance: none;
}

img {
    font-family: 'Metropolis';
    border: none;
    height: auto;
    max-width: 100%;
    outline: none;
    vertical-align: middle;
}

iframe {
    font-family: 'Metropolis';
    vertical-align: middle;
}

.launch-section {
    background: url("../images/launch-bg.png") no-repeat;
    background-size: cover;
    background-position: center;
    padding: 22px 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-area {
    background: #fff;
    padding: 30px;
    border-radius: 31px;
}

img.login-logo {
    display: flex;
    margin: 0 auto 15px;
}
.login-area h1 {
    text-align: center;
    font-size: 38px;
    color: #000;
    font-weight: 700;
    margin-bottom: 40px;
}
.login-area input {
    border-radius: 70px !important;
    border: 1px solid #F1F7FF;
    background: #E8F0F8;
    padding: 13px 14px;
}
.login-area label {
    color: #0D132C;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 0 2px;
}
.login-area input::placeholder {
    font-size: 14px;
}
.login-area .form-control:focus {
    border: 1px solid #F1F7FF;
    box-shadow: none;
    background: #E8F0F8;
    outline: transparent;
}
.login-area form button {
    background: #042AFC !important;
    color: #fff !important;
    display: flex;
    margin: 0 auto 0;
    padding: 9px 48px;
    border-radius: 180px;
    font-size: 17px;
}
.sidebar {
    background: #E8F0F8;
    width: 260px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 100px 20px 20px;
}
header {
    background: #042AFC;
    position: fixed;
    width: 100%;
    z-index: 1;
}
.sidebar ul li {
    list-style: none;
    padding: 10px 0;
}
.sidebar ul li button {
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    text-align: left;
    position: relative;
}
.sidebar ul li a {
    color: #042AFC !important;
    font-weight: 500;
    opacity: 0.6;
    text-decoration: unset;
}
.sidebar ul li svg {
    opacity: 0.6;
}
.sidebar .dropdown-toggle::after {
    display: none;
}
.sidebar ul li svg {
    opacity: 0.6;
    margin-right: 10px;
}
.sidebar ul 
 li.active a svg {
    opacity: unset;
}
.sidebar ul 
 li.active a {
    opacity: unset;
 }
 .btn:hover {
    background: unset;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    background: unset;
}
.sidebar .dropdown-menu.show {
    position: relative !important;
    transform: unset !important;
    border: none;
    background: transparent;
    padding: 10px 10px 0 20px;
}
.common-area {
    padding: 90px 10px 30px 280px;
}
.head-area h3 {
    font-size: 24px;
    color: #0D132C;
    font-weight: 500;
}
.head-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.search-area {
    display: flex;
    align-items: end;
}
.head-search svg {
    position: absolute;
    top: 12px;
    left: 13px;
}
.head-search {
    position: relative;
    margin-right: 6px;
}
.head-search input {
    border-radius: 12px;
    border: 1px solid rgba(31, 37, 60, 0.19);
    background: #FFF;
    width: 500px;
    padding: 10px 10px 10px 40px;
}
.search-area .css-13cymwt-control {
    border-radius: 12px;
    border: 1px solid rgba(31, 37, 60, 0.19);
    background: #FFF;
    padding: 4px;
}
.search-area span.css-1u9des2-indicatorSeparator {
    background-color: transparent;
}
.search-area .css-t3ipsp-control:hover {
    border-color: rgba(31, 37, 60, 0.19);
}

.search-area .css-t3ipsp-control {
    border-radius: 12px;
    border: 1px solid rgba(31, 37, 60, 0.19);
    background: #FFF;
    padding: 4px;
}
.user-maagement-table {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 10.93px 0px rgba(0, 0, 0, 0.11);
    overflow: hidden;
}
.user-maagement-table thead tr th {
    background: #E8F0F8;
    color: #0D132C;
    font-weight: 500;
    padding: 15px;
}
.user-maagement-table tr td {
    vertical-align: middle;
    color: #1F253C;
    font-size: 14px;
    padding: 15px;
}
.user-maagement-table tbody tr {
    border-bottom: 1px solid #a2acc842;
}
button.enable-btn {
    border-radius: 12px;
    background: #DDFDE6 !important;
    color: #008826 !important;
    font-size: 14px;
}
.user-maagement-table tr td button {
    margin: 0 4px;
}
button.disable-btn {
    border-radius: 12px;
    background: #F0F3F1 !important;
    color: #A8A8A8 !important;
    font-size: 14px;
}
.user-maagement-table tr td img {
    width: 33px;
    min-width: 33px;
    height: 33px;
    min-height: 33px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
.navbar-nav a.nav-link img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    border: 2px solid #fff;
}
.sidebar-footer {
    border-radius: 12px;
    background: #042AFC;
    position: absolute;
    bottom: 12px;
    left: 12px;
    width: 90%;
    padding: 0 15px 20px;
    text-align: center;
}
.sidebar-footer button {
    background: #F1F7FF !important;
    color: #042afc !important;
    font-weight: 600;
    padding: 7px 50px;
    border-radius: 20px;
    margin-top: 15px;
}
.dashboard-area h6 {
    color: #606779;
    font-size: 24px;
    font-weight: 500;
}
.dashboard-area h6 span {
    font-style: italic;
    color: #000;
}
.dashboard-area p {
    color: #1F253C;
    padding: 5px 0 15px;
}
.dashboard-area img {
    width: 100%;
}
.total-creaters-area {
    border-radius: 12px;
    background: #E8F0F8;
    box-shadow: 0px 0px 18px 0px rgba(101, 168, 235, 0.49);
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
.creater-dashboard-area.row {
    justify-content: space-between;
    margin: 25px 0;
}
.total-creaters-area p {
    color: #1F253C;
    margin-bottom: 5px;
}
.total-creaters-area h5 {
    color: #0D132C;
    font-size: 20px;
    font-weight: 600;
}
.request-listing {
    border-radius: 14.651px;
    background: var(--Light-Version-Surface-White, #FFF);
    box-shadow: 0px 0px 17.93px 0px rgba(0, 0, 0, 0.11);
    padding: 20px;
    overflow-y: auto;
}
.request-listing-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 15px;
}
.request-listing-top h6 {
    color: #1F253C;
}
.request-listing-top .css-13cymwt-control, .request-listing-top .css-t3ipsp-control {
    border-radius: 190px;
    border: 0.571px solid #606779;
    box-shadow: 0px 0px 3.429px 0px #FFF;
    min-height: 30px;
}
.request-listing-top .css-13cymwt-control:hover, .request-listing-top .css-t3ipsp-control:hover {
    border: 0.571px solid #606779;
}
.request-listing-top .css-1jqq78o-placeholder {
    font-size: 14px;
}
.request-listing-top .css-1u9des2-indicatorSeparator {
 background-color: transparent;   
}
.request-listing-top .css-hlgwow {
    padding: 0 8px;
}
.request-listing-top .css-1xc3v61-indicatorContainer {
    padding: 0 8px;
}
.request-listing-top .css-1dimb5e-singleValue {
    font-size: 14px;
}
.request-listing-top .css-tj5bde-Svg {
    fill: #606779;
}
.user-type-area {
    display: flex;
    align-items: center;
}
.user-type-area img {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
.user-type-area h6 {
    color: #1F253C;
    font-size: 15px;
    font-weight: 400;
}
.user-type-area p {
    color: #606779;
    font-size: 15px;
    font-weight: 400;
}
.request-listing .table tr td {
    color: #606779;
    font-size: 15px;
    vertical-align: middle;
    padding: 15px 10px;
}
button.approve-btn, button.approve-btn:hover {
    border-radius: 18.569px;
    border: 0.774px solid #F1F7FF;
    background: #DDFDE6;
    color: #008826 ;
    font-size: 14px;
    padding: 5px 15px;
    border: none;
    font-weight: 500;
}
button.reject-btn, button.reject-btn:hover {
    border-radius: 18.569px;
    border: 0.774px solid #F1F7FF;
    background: #FDDDDD;
    color: #D60000;
    font-size: 14px;
    padding: 5px 15px;
    border: none;
    font-weight: 500;
}
.request-listing .table tr td button {
    margin: 0 2px;
}
.request-listing .table tr th {
    accent-color: #1F253C;
    font-weight: 500;
    font-size: 15px;   
    padding: 20px 10px;
    width: 33%;
}
.no-data-area img {
    width: auto !important;
    min-width: auto !important;
    height: auto !important;
    border-radius: 0 !important;
    object-fit: none !important;
}
.no-data-area {
    text-align: center;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.no-data-area h6 {
    font-size: 19px;
    color: #000;
    font-weight: 500;
    margin-top: 15px;
}
.sidebar-footer p span {
    font-weight: 600;
}
.sidebar-footer p {
    color: #fff;
}
.sidebar-footer img {
    margin-top: -90px;
}
.sidebar .dropdown-item:focus, .sidebar .dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: transparent;
}
.creator-offcanvas.offcanvas.offcanvas-end {
    width: 600px !important;
}
.creator-offcanvas.offcanvas.offcanvas-end form input {
    border-radius: 24px;
    border: 1px solid #F1F7FF;
    background: #E8F0F8;
    padding: 9px 12px;
    font-size: 14px;
}
.creator-offcanvas.offcanvas.offcanvas-end form label {
    color: #1F253C;
    font-size: 14px;
    margin: 0;
    font-weight: 500;
}
.creator-offcanvas.offcanvas.offcanvas-end .form-control:focus {
    box-shadow: unset;
}
.search-area label {
    margin: 0;
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
.chart-area {
    border-radius: 14.651px;
    background: var(--Light-Version-Surface-White, #FFF);
    box-shadow: 0px 0px 17.93px 0px rgba(0, 0, 0, 0.11);
    padding: 20px;
    height: 100%;
}
.swal2-icon-show {
    margin: 0 auto !important;
}
.swal2-confirm, .swal2-confirm:hover, .swal2-confirm:active {
    background: #042AFC;
    border-radius: 32px;
    color: #fff;
    font-size: 15px;
    padding: 7px 30px;
    border: 2px solid #042AFC !important;
}
.swal2-cancel, .swal2-cancel:hover, .swal2-cancel:active {
    border: 2px solid #042AFC !important;
    border-radius: 32px;
    color: #042AFC !important;
    font-size: 15px;
    padding: 7px 30px;
    background: transparent;
    margin-right: 11px;
}
.swal2-popup {
    padding: 42px;
    border-radius: 28px;
}
.swal2-html-container {
    font-size: 16px !important;
}
.action-btns {
    display: flex;
}
button.view-reason-btn.btn.btn-primary {
    background: #042afc;
    border: none;
    font-size: 13px;
    padding: 6px 14px;
    border-radius: 22px;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.reject-reason-modal .modal-content {
    border-radius: 20px;
}
.reject-reason-modal .modal-title {
    font-size: 18px;
}
.reject-reason-modal .btn.btn-primary {
    padding: 5px 25px;
    border-radius: 20px;
    background: rgb(172, 24, 24);
    border: none;
    font-size: 15px;
}
.pagination li a {
    background: #F1F7FF;
    width: 45px;
    height: 45px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #042afc;
    text-decoration: none;
    margin-right: 12px;
    cursor: pointer;
}
.pagination  li.active a {
    background: #042afc;
    color: #fff;
}
.css-b62m3t-container {

    width: 140px;
}
ul.pagination {
    margin-top: 20px;
}
/* media-query */
@media (max-width: 1440px) { 
    .request-listing .table tr th {
        width: unset;
    }
    .sidebar {
        width: 236px;
    }
    .common-area {
        padding: 90px 10px 30px 250px;
    }
}